var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { SubscriptionTypeAnon } from 'graphql-ts-types';
import { color, display, fontSize, height, lineHeight, space, textAlign, width, } from 'styled-system';
import { ButtonLink } from '../../common/components/Button';
import { Flex } from '../../common/components/Flex';
import { ResponsiveLazyImage } from '../../common/components/LazyImage';
import { linkStyles } from '../../common/components/Link';
import Modal from '../../common/components/Modal';
import NewsletterSignupInput from '../../common/components/NewsletterSignupInput';
import RichContent from '../../common/components/RichText/RichContent';
import { Heading } from '../../common/components/Text';
import { NewsletterSignupName } from '../../common/enums/Tracking';
import { usePromoEvents } from '../../common/hooks/dynamic-yield';
import { useShowOverlay } from '../../common/hooks/show-overlay';
import { boxSizing } from '../../common/theme/system-utilities';
var getNewsletterTrackingData = function (tracking, isFullScreen) { return (__assign(__assign({}, tracking), { name: isFullScreen
        ? NewsletterSignupName.NewsletterPopUpFullScreen
        : NewsletterSignupName.NewsletterPopUpMinimized })); };
var MARGIN_TOP = ['sp_16', 'sp_20', null, 'sp_24'];
var FULL_SCREEN_MODAL_POSITION = {
    top: ['0', '50%'],
    translateCoordinates: ['none', '-50%, -50%, 0'],
};
var Image = styled(ResponsiveLazyImage)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), display, width, height);
Image.defaultProps = {
    display: 'block',
    width: 1,
    height: ['auto', '187px'],
};
var HeadingText = styled(Heading)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), width);
HeadingText.defaultProps = __assign(__assign({}, Heading.defaultProps), { color: 'sys.neutral.border.strong', fontSize: ['fs_16', 'fs_18', 'fs_20', 'fs_24'], lineHeight: ['lh_20', 'lh_22', 'lh_24', 'lh_28'], textAlign: 'center', mb: ['sp_8', 'sp_12'], width: 1 });
var StyledRichContent = styled(RichContent)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  p {\n    ", ";\n    ", ";\n    ", ";\n\n    > a {\n      ", ";\n      color: inherit;\n    }\n  }\n\n  ol,\n  ul {\n    ", "\n  }\n"], ["\n  ", ";\n  ", ";\n  p {\n    ", ";\n    ", ";\n    ", ";\n\n    > a {\n      ", ";\n      color: inherit;\n    }\n  }\n\n  ol,\n  ul {\n    ", "\n  }\n"])), textAlign, width, fontSize, lineHeight, color, function (props) {
    return linkStyles(__assign({ styled: true, textDecoration: 'underline' }, props));
}, color);
StyledRichContent.defaultProps = {
    color: 'sys.neutral.border.hover',
    textAlign: 'center',
    width: 1,
};
var CtaLink = styled(ButtonLink)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), width, space, boxSizing);
CtaLink.defaultProps = __assign(__assign({}, ButtonLink.defaultProps), { width: 1, mt: ['sp_20', null, null, 'sp_28'], boxSizing: 'border-box' });
var Asterisk = styled(StyledRichContent)(templateObject_5 || (templateObject_5 = __makeTemplateObject([""], [""])));
Asterisk.defaultProps = __assign(__assign({}, StyledRichContent.defaultProps), { fontSize: ['fs_14', null, null, 'fs_16'], lineHeight: ['lh_16', null, null, 'lh_24'], mt: MARGIN_TOP });
// eslint-disable-next-line complexity
var PopUp = function (_a) {
    var isFullScreen = _a.isFullScreen, backgroundColor = _a.backgroundColor, headline = _a.headline, headlineColor = _a.headlineColor, headingText = _a.headingText, headingColor = _a.headingColor, image = _a.image, contentText = _a.contentText, contentColor = _a.contentColor, asteriskText = _a.asteriskText, asteriskColor = _a.asteriskColor, showNewsletterInputField = _a.showNewsletterInputField, showCta = _a.showCta, ctaButtonType = _a.ctaButtonType, ctaButtonHref = _a.ctaButtonHref, ctaButtonLabel = _a.ctaButtonLabel, tracking = _a.tracking, triggerType = _a.triggerType, timeOnPage = _a.timeOnPage, newsletterType = _a.newsletterType;
    var showCtaLink = showCta && ctaButtonLabel && ctaButtonHref.fullUrl;
    var isShowPopupTriggered = useShowOverlay(triggerType, timeOnPage);
    var _b = usePromoEvents(tracking), onPromoView = _b.onPromoView, onPromoClick = _b.onPromoClick, onPromoClose = _b.onPromoClose;
    var _c = __read(useState(false), 2), isPopUpOpen = _c[0], setIsPopUpOpen = _c[1];
    var newsletterTrackingData = useMemo(function () { return getNewsletterTrackingData(tracking, isFullScreen); }, [tracking, isFullScreen]);
    var onClosePopUp = useCallback(function () {
        setIsPopUpOpen(false);
        onPromoClose();
    }, []);
    useEffect(function () {
        if (isShowPopupTriggered) {
            onPromoView();
        }
        setIsPopUpOpen(isShowPopupTriggered);
    }, [isShowPopupTriggered, onPromoView]);
    return (React.createElement(Modal, __assign({ isOpen: isPopUpOpen, headerTitle: headline, isFullScreen: isFullScreen, color: headlineColor, onClose: onClosePopUp }, (backgroundColor && { bg: backgroundColor }), (isFullScreen && FULL_SCREEN_MODAL_POSITION)),
        image && React.createElement(Image, __assign({}, image)),
        React.createElement(Flex, { flexDirection: "column", alignItems: "center", px: ['sp_16', 'sp_20', null, 'sp_32'], pt: MARGIN_TOP, pb: ['sp_16', 'sp_24'] },
            headingText && (React.createElement(HeadingText, { color: headingColor }, headingText)),
            React.createElement(StyledRichContent, { content: contentText, color: contentColor }),
            showNewsletterInputField && (React.createElement(Flex, { mt: MARGIN_TOP, width: 1, flexDirection: "column" },
                React.createElement(NewsletterSignupInput, { buttonKind: ctaButtonType, tracking: newsletterTrackingData, promoClickOnSubmit: onPromoClick, newsletterType: newsletterType }))),
            showCtaLink && (React.createElement(CtaLink, __assign({ kind: ctaButtonType, href: ctaButtonHref.fullUrl, onClick: onPromoClick }, (ctaButtonHref.target && { target: ctaButtonHref.target })), ctaButtonLabel)),
            asteriskText && (React.createElement(Asterisk, { content: asteriskText, color: asteriskColor })))));
};
PopUp.defaultProps = {
    newsletterType: SubscriptionTypeAnon.Newsletter,
};
export default PopUp;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
